import axios from "axios";
import React, { useEffect, useState } from "react";

function App() {
  const [styles, setStyles] = useState({});
  const [imageIndices, setImageIndices] = useState({});
  const [renderedImagesCount, setRenderedImagesCount] = useState(0);

  const styleNames = Object.keys(styles);
  const totalImages = styleNames.reduce((sum, e) => sum + 1, 0);

  useEffect(() => {
    const fetchStyles = async () => {
      try {
        const response = await axios.get("https://agent.zap.marketing/styles");
        console.log(response)
        delete response.data["__midjourney"];
        delete response.data["sticker"];
        delete response.data["nobo"];
        delete response.data["poel"];
        delete response.data["simax"];
        
        setStyles(response.data);

        const keys = Object.keys(response.data);
        const indices = keys.reduce((accumulator, currentKey) => {
          accumulator[currentKey] = 0;
          return accumulator;
        }, {});
        setImageIndices(indices);
      
      } catch (error) {
        console.error("Failed to fetch styles:", error);
      }
    };
    fetchStyles();
  }, []);

  const handleImageLoad = () => {
    setRenderedImagesCount((prevCount) => prevCount + 1);
  };

  const handleImageClick = (event: any, name: string) => {
    event.preventDefault();
    const newIndices = { ...imageIndices };

    if (newIndices[name] >= styles[name].images.length - 1) {
      newIndices[name] = 0;
    } else {
      newIndices[name]++;
    }

    setImageIndices(newIndices);
  };

  const openWhatsApp = (event: any, name: string) => {
    event.preventDefault();
    const text = `${name}: glamping`
    window.location.href = `https://api.whatsapp.com/send?phone=+14156102615&text=${encodeURIComponent(
      text,
    )}`;
  };

  return (
    <div className="bg-neutro-1 px-2.4 py-[30px] min-h-screen h-full relative flex flex-col">
      <div
        style={{ width: "100%" }}
        className="mx-auto px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8"
      >
        <img width={100} src="/logo.png" alt="Logo" />
        <div
          style={{
            opacity:
              renderedImagesCount >= totalImages && styleNames.length > 0
                ? undefined
                : 0,
          }}
          className={`${
            renderedImagesCount >= totalImages && styleNames.length > 0
              ? "fadeInUp"
              : ""
          } mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8`}
        >
          {styleNames.map((name, index) => (
            <div className="group relative" key={index}>
              <div onClick={(e) => handleImageClick(e, name)}>
                <div className="aspect-content">
                  <span
                    style={{ position: "absolute", right: 10, top: 10 }}
                    className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-medium font-large text-blue-700 ring-1 ring-inset ring-blue-600/20"
                  >
                    Pro
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      left: 10,
                      top: 10,
                      fontSize: 25,
                    }}
                  >
                    🔄
                  </span>
                  <img
                    src={styles[name].images[imageIndices[name]]}
                    alt={name}
                    onLoad={handleImageLoad}
                    className="object-cover object-center h-full w-full cursor-pointer"
                  />
                </div>
              </div>
              <div className="mt-4 flex justify-between text-gray-500">
                <div style={{ marginTop: 5 }}>{name}: glamping</div>
                <a
                  className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full bg-zinc-900 py-1 px-3 text-white hover:bg-zinc-700 dark:bg-emerald-400/10 dark:text-emerald-400 dark:ring-1 dark:ring-inset dark:ring-emerald-400/20 dark:hover:bg-emerald-400/10 dark:hover:text-emerald-300 dark:hover:ring-emerald-300"
                  onClick={(e) => openWhatsApp(e, name)}
                >
                  Select
                  <svg
                    viewBox="0 0 20 20"
                    fill="none"
                    aria-hidden="true"
                    className="mt-0.5 h-5 w-5 -mr-1"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
